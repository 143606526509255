import './Main.css';
import React, { Component } from 'react'
import Search from '../search/Search';
import Booking from '../booking/Booking';
import Login from '../login/Login';
import Dashboard from '../../components/dashboard/Dashboard';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

export class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {
            roomId: 0,
            startDate: '',
            endDate: '',
            price: 0,
            discountedPrice: 0,
            currentRoom: '',
            currentLockerType: '',
            currentLockerTypeId: 0,
            customerName: '',
            customerEmail: '',
            customerPhone: '',
            seasonBooking: false,
            availabilities: null,
            updatePrice: false,
            discountCode: '',
            discount: null,
            roomList: 0,
            defaultSkiLocker: 0,
            defaultPrice: 0,
            cgvAccepted: false,
            bookingDurationRestricted: false,
            minimumDuration: null,
            maximumDuration: null,
            minimumDaysBefore: null,
        }
    }



    // Next step function
    nextStep = () => {
        this.props.nextStep()
    }

    // Previous step function
    prevStep = () => {
        this.props.prevStep()
    }

    changeStep = (newStep) => {
        this.props.changeStep(newStep);
    }

    // Set resort Id
    setResort = (resortId) => {
        this.setState({
            roomId: resortId,
            updatePrice: true,
        });
    }

    // Set booking Start Date
    setStartDate = (startDate) => {
        // const { endDate } = this.state;
        // if(endDate !== ''){
        //   this.setState({
        //     startDate: startDate,
        //     updatePrice: true,
        //   });
        // }
        // else{      
        //   this.setState({
        //     startDate: startDate,
        //     updatePrice: true,
        //   });
        // }
        this.setState({
            startDate: startDate,
            // updatePrice: true,
        });
    }

    // Set booking End Date
    setEndDate = (endDate) => {
        this.setState({
            endDate: endDate,
            updatePrice: true,
        });
    }

    setCurrentRoom = (room) => {
        this.setState({
            currentRoom: room,
            roomId: room.id,
            currentLockerType: '',
            currentLockerTypeId: 0,
            updatePrice: true,
            bookingDurationRestricted: false
        });
    }

    setCurrentLockerType = (lockerTypeLabel, lockerTypeId) => {
        this.setState({
            currentLockerType: lockerTypeLabel,
            currentLockerTypeId: lockerTypeId,
            updatePrice: true,
        });
    }

    setCustomer = (customerName, customerEmail, customerPhone) => {
        this.setState({
            customerName: customerName,
            customerEmail: customerEmail,
            customerPhone: customerPhone,
        });
    }

    setCustomerName = (customerName) => {
        this.setState({
            customerName: customerName,
            updatePrice: true,
        });
    }

    setCustomerEmail = (customerEmail) => {
        this.setState({
            customerEmail: customerEmail,
            updatePrice: true,
        });
    }

    setCustomerPhone = (customerPhone) => {
        this.setState({
            customerPhone: customerPhone,
            updatePrice: true,
        });
    }

    setCgvaccepted = () => {
        this.setState({
            cgvAccepted: !this.state.cgvAccepted,
        });
    }

    setSeason = (checked) => {
        this.setState({
            seasonBooking: checked,
            updatePrice: true,
        });
    }

    setDiscountCode = (discountcode) => {
        this.setState({
            discountCode: discountcode,
        });
    }

    setCustomerId = (customerId) => {
        this.props.setCustomerId(customerId);
    }

    getDiscount = () => {
        const { discountCode, currentRoom } = this.state;
        const { apiEndpoint, apiAuth } = this.props;
        console.log('discountCode: ' + discountCode);
        console.log('currentRoom.id: ' + currentRoom.id);
        let route = apiEndpoint + '/discounts';
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            },
            Authorization: 'Basic ' + apiAuth,
            body: JSON.stringify({
                'resortId': currentRoom.id,
                'discountCode': discountCode,
            })
        };
        fetch(route, requestOptions)
            .then(res => res.json())
            .then((data) => {
                if (data.status === 'success') {
                    this.setState({
                        discount: data.discount,
                        updatePrice: true,
                    });
                }
                else {
                    this.setState({
                        discount: null,
                        discountedPrice: 0,
                        updatePrice: true,
                    });
                }
            })
            .catch(error => console.log(error))
    }

    setPrice() {
        const { currentRoom, startDate, endDate, currentLockerTypeId, seasonBooking, discount } = this.state;
        const { apiEndpoint, apiAuth } = this.props;
        const roomId = currentRoom.id;
        if (
            (roomId !== 0 && startDate !== '' && endDate !== '' && currentLockerTypeId !== 0 && seasonBooking === false) ||  // range date booking
            (roomId !== 0 && seasonBooking === true && currentLockerTypeId !== 0)  // season booking
        ) {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                },
                Authorization: 'Basic ' + apiAuth
            };

            let route;
            // if season booking get season webservice
            if (seasonBooking === true) {
                route = apiEndpoint + '/availabilities/' + roomId + '?' + new URLSearchParams({
                    'startDate': startDate,
                    'endDate': endDate,
                    'seasonBooking': true,
                    'lockerTypeId': currentLockerTypeId
                });
            }
            // if not season booking get range date price
            else {
                route = apiEndpoint + '/availabilities/' + roomId + '?' + new URLSearchParams({
                    'startDate': startDate,
                    'endDate': endDate,
                    'lockerTypeId': currentLockerTypeId
                });
            }
            // Get availabilities
            fetch(route, requestOptions)
                .then(res => res.json())
                .then((data) => {
                    this.setState({
                        bookingDurationRestricted: data.nbDaysNotGood,
                    });
                    this.setState({
                        bookingBeforeDurationRestricted: data.nbDaysBeforeNotGood,
                    });
                    this.setState({
                        minimumDuration: data.minimumDuration,
                    });
                    this.setState({
                        maximumDuration: data.maximumDuration,
                    });
                    this.setState({
                        minimumDaysBefore: data.minimumDaysBefore,
                    });

                    if (discount != null) {
                        if (discount.type === 'percent') {
                            this.setState({
                                defaultPrice: data.price,
                                price: data.discountedPrice,
                                defaultSkiLocker: data.defaultSkilocker,
                                discountedPrice: (data.discountedPrice * discount.value).toFixed(2),

                            });
                        }
                        else {
                            this.setState({
                                defaultPrice: data.price,
                                price: data.discountedPrice,
                                defaultSkiLocker: data.defaultSkilocker,
                                discountedPrice: data.discountedPrice - discount.value,
                            });
                        }
                    }
                    else {
                        this.setState({
                            defaultPrice: data.price,
                            price: data.discountedPrice,
                            defaultSkiLocker: data.defaultSkilocker,
                        });
                    }
                })
                .catch(error => console.log(error))
        }
        this.setState({
            updatePrice: false,
        });
    }

    updateRoomList = () => {
        const { roomList } = this.state;
        this.setState({
            roomList: roomList + 1
        });
    }

    render() {
        // Main info
        const { step, dictionary, rooms, customerId, apiEndpoint } = this.props;
        // Date info
        const { startDate, endDate, seasonBooking } = this.state;
        // Room - Locker info
        const { currentRoom, currentLockerType, currentLockerTypeId, defaultSkiLocker } = this.state;
        // Price info
        const { price, updatePrice, discountCode, defaultPrice, bookingDurationRestricted, discountedPrice, minimumDuration, maximumDuration, minimumDaysBefore } = this.state;
        // Customer info
        const { customerName, customerEmail, customerPhone, cgvAccepted } = this.state;
        // Refresh variable
        const { roomList } = this.state;

        if (updatePrice) {
            this.setPrice();
        }

        return (
            <Router>
                <Switch>
                    <Route path="/login">
                        {step < 3 &&
                            <div className="main">
                                <Login
                                    changeStep={this.changeStep}
                                    setCustomerId={this.setCustomerId}
                                    apiEndpoint={apiEndpoint}
                                />
                            </div>
                        }
                        {step === 3 &&
                            <div className="main">
                                <Dashboard
                                    changeStep={this.changeStep}
                                    customerId={customerId}
                                    rooms={rooms}
                                    customerName={customerName}
                                    customerEmail={customerEmail}
                                    customerPhone={customerPhone}
                                    setCustomer={this.setCustomer}
                                    apiEndpoint={apiEndpoint}
                                />
                            </div>
                        }
                    </Route>
                    <Route path="/">
                        {step <= 1 &&
                            <div className="main">
                                <Search
                                    step={step}
                                    prevStep={this.prevStep}
                                    nextStep={this.nextStep}
                                    setStartDate={this.setStartDate}
                                    setEndDate={this.setEndDate}
                                    rooms={rooms}
                                    setLockerType={this.setCurrentLockerType}
                                    setCurrentRoom={this.setCurrentRoom}
                                    currentRoom={currentRoom}
                                    dictionary={dictionary}
                                    currentLockerType={currentLockerType}
                                    customerName={customerName}
                                    customerEmail={customerEmail}
                                    customerPhone={customerPhone}
                                    setCustomerName={this.setCustomerName}
                                    setCustomerEmail={this.setCustomerEmail}
                                    setCustomerPhone={this.setCustomerPhone}
                                    setSeason={this.setSeason}
                                    seasonBooking={seasonBooking}
                                    startDate={startDate}
                                    endDate={endDate}
                                    roomList={roomList}
                                    setCustomerId={this.setCustomerId}
                                    customerId={customerId}
                                    apiEndpoint={apiEndpoint}
                                />

                                <Booking
                                    step={step}
                                    price={price}
                                    startDate={startDate}
                                    endDate={endDate}
                                    rooms={rooms}
                                    currentRoom={currentRoom}
                                    currentLockerType={currentLockerType}
                                    currentLockerTypeId={currentLockerTypeId}
                                    customerName={customerName}
                                    customerEmail={customerEmail}
                                    customerPhone={customerPhone}
                                    discountCode={discountCode}
                                    defaultSkiLocker={defaultSkiLocker}
                                    defaultPrice={defaultPrice}
                                    seasonBooking={seasonBooking}
                                    setDiscountCode={this.setDiscountCode}
                                    setCurrentRoom={this.setCurrentRoom}
                                    prevStep={this.prevStep}
                                    nextStep={this.nextStep}
                                    updateRoomList={this.updateRoomList}
                                    setCustomerId={this.setCustomerId}
                                    customerId={customerId}
                                    cgvAccepted={cgvAccepted}
                                    setCgvaccepted={this.setCgvaccepted}
                                    bookingDurationRestricted={bookingDurationRestricted}
                                    minimumDuration={minimumDuration}
                                    maximumDuration={maximumDuration}
                                    minimumDaysBefore={minimumDaysBefore}
                                    getDiscount={this.getDiscount}
                                    discountedPrice={discountedPrice}
                                    apiEndpoint={apiEndpoint}
                                />
                            </div>
                        }
                        {step === 2 &&
                            <div className="main">
                                <Login
                                    changeStep={this.changeStep}
                                    setCustomerId={this.setCustomerId}
                                    apiEndpoint={apiEndpoint}
                                />
                            </div>
                        }
                        {step === 3 &&
                            <div className="main">
                                <Dashboard
                                    changeStep={this.changeStep}
                                    customerId={customerId}
                                    rooms={rooms}
                                    customerName={customerName}
                                    customerEmail={customerEmail}
                                    customerPhone={customerPhone}
                                    setCustomer={this.setCustomer}
                                    apiEndpoint={apiEndpoint}
                                />
                            </div>
                        }
                    </Route>
                </Switch>
            </Router>

        );

    }
}

export default Main;
