import 'leaflet/dist/leaflet.css';
import './LeafletMap.css';
import React, { Component } from 'react';
import markerIconPng from "leaflet/dist/images/marker-icon.png"
// import markerIconPng from "leaflet/dist/images/marker-icon-red.png"
import {Icon} from 'leaflet'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';



export class LeafletMap extends Component{
    // eslint-disable-next-line no-useless-constructor
    constructor(props){
        super(props);
      }

      handleClick = e => {
        e.preventDefault();
        const { rooms } = this.props;
        const room = rooms.find(x => x.name === e.target.name);        
        this.props.setResort(room);
      }

      render(){
          const { rooms, position, zoom, width, height } = this.props;
          const roomCount = rooms.length;
          return(
              <div style={{ width: width, height: height }} className="maps">
                <MapContainer key={position} center={position} zoom={zoom} scrollWheelZoom={true} style={{ height: '100%', width: '100%' }}>
                    <TileLayer
                    attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    
                    {rooms.map((room, i) => {                
                        // Return the element. Also pass key     
                        return (
                            <Marker key={room.id} position={[room.latitude,room.longitude]} icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}>
                                <Popup>
                                    <strong>{room.name}</strong> <br />
                                    {room.address}<br />
                                    {room.zip}&nbsp;{room.city} <br />
                                    <div style={{display: (roomCount > 1)?'block':'none', textAlign: "center"}}>
                                        <button className="button button-blue-light" style={{margin: "10px auto 0 auto"}} name={room.name} onClick={this.handleClick} >Sélectionner</button>
                                    </div>
                                </Popup>
                            </Marker>
                        ) 
                    })}
                </MapContainer>
              </div>
            
          )
    }
}

export default LeafletMap;