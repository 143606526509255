import './Booking.css';
import React, { Component } from 'react'
import LeafletMap from '../../components/leaflet/LeafletMap';
import StripePayment from '../../components/stripe/Stripe';
import Moment from 'moment';


export class Booking extends Component{

    // eslint-disable-next-line no-useless-constructor
    constructor(props){
        super(props);
    }
    

    handleChange = e =>{
        this.props.setDiscountCode(e.target.value);
    }

    handleCheck = e =>{
        e.preventDefault();
        this.props.setCgvaccepted();
    }

    handleClick = e =>{
        e.preventDefault();
        this.props.getDiscount();
    }

    setResort = (room) =>{
        const { step } = this.props;
        if(room != null){
            const value = room.name;
            if(value !== ''){
                if(room !== undefined){
                    this.props.setCurrentRoom(room);
                    if(step === 0){
                        this.props.nextStep();
                    }
                    else{
                        this.props.setLockerType('');
                    }
                }
            }
        }
    }
    

    render(){
        const { step, endDate, startDate, price, discountedPrice, currentRoom, rooms, currentLockerType,currentLockerTypeId, discountCode, defaultSkiLocker, defaultPrice, cgvAccepted, bookingDurationRestricted, seasonBooking, minimumDuration, maximumDuration, minimumDaysBefore } = this.props;
        let night = 0;
        let amount = price;
        if(discountedPrice > 0){
            amount = discountedPrice;
        }

        if(startDate !== '' && endDate !== ''){
            night = Moment(endDate,"YYYY-MM-DD").diff(Moment(startDate,"YYYY-MM-DD"),'days') + 1;
        }
        

        if(step === 0){
            const position = [45.505, -0.09];            
            const width = '75vw';            
            const height = '75vh';
            return(
                <div className="Booking">
                    <LeafletMap rooms={rooms} position={position} zoom={6} width={width} height={height} setResort={this.setResort} />
                </div>
            );
        }

        if(step >= 1){            
            const position = [currentRoom.latitude, currentRoom.longitude];
            const width = '100%';            
            const height = '65%';

            if (amount == undefined || amount == -1) {
                amount = 0;
            }

            return(
                <div className="Booking">
                    <div className="ResortDetail">
                        <h2>{currentRoom.name}</h2>
                        <p><br />
                            <i>Consigne ouverte du <b>{Moment(currentRoom.openDate).format('DD/MM/YYYY')}</b> au <b>{Moment(currentRoom.closeDate).format('DD/MM/YYYY')}</b></i>
                        </p>
                        <p>
                            <i>Casier utilisable du premier jour <u>{currentRoom.openHour}</u> jusqu'au dernier jour <u>{currentRoom.closeHour}</u></i>
                        </p>
                        <div className="RoomMap">
                            <LeafletMap rooms={[currentRoom]} position={position} zoom={15} width={width} height={height} />
                        </div> 
                    </div>
                    <div className="Statement">
                        <h2>Votre Réservation</h2>
                        {currentRoom !== '' &&
                            <div className="where">
                                <img src="/img/pin.png" alt="location" className="picto" />{currentRoom.name}
                            </div>
                        }
                        {(currentRoom.isBookingOpen === 0 || currentRoom.resortDisplay === "0" || currentRoom.isBookingOpen == "0") &&
                            <div className="bookingDisabled">
                                Les réservations pour cette consigne ne sont pas encore ouvertes, nous vous invitons à revenir ultérieurement.
                            </div>
                        }
                        {currentLockerType !== '' &&
                            <div className="where">
                                <img src="/img/lockers.png" alt="locker" className="picto" />{currentLockerType}
                            </div>
                        }
                        <div className="when">
                            {startDate !== '' && endDate !== '' &&
                            <div>
                                <div>    
                                    <img src="/img/booking.png" alt="booking" className="picto" />
                                    Du&nbsp;<strong>{Moment(startDate).format('DD/MM/YYYY')}</strong>&nbsp;au&nbsp;<strong>{Moment(endDate).format('DD/MM/YYYY')}</strong>
                                </div>
                                <div>
                                    <img src="/img/future.png" alt="clock" className="picto" />
                                    De&nbsp;<b>{currentRoom.openHour}</b>&nbsp;à&nbsp;<b>{currentRoom.closeHour}</b>
                                </div>
                            </div>
                                
                            }
                            
                            
                        </div>    
                        
                        
                        {/* {currentRoom !== '' && currentLockerType !== '' && amount !== 0 && startDate !== '' && endDate !== '' && night !== 0 &&
                            <div className="discount">
                                 <img src="/img/discount.png" alt="discount" className="picto" />
                                <input type="text" name="dicountCode" placeholder="Code promo" value={discountCode} onChange={this.handleChange} />
                                <button type="submit" className="button button-transparent" onClick={this.handleClick}>
                                    Appliquer
                                </button>
                            </div>
                        }             */}
                        <div className="price">
                            {amount !== 0 && bookingDurationRestricted === false && currentRoom !== '' && currentLockerType !== '' && startDate !== '' && endDate !== '' && night !== 0 &&
                                <p>                            
                                    <span>{amount}€</span>
                                </p>
                            }
                        </div>
                        {   
                            (
                                bookingDurationRestricted === true && currentRoom !== '' && minimumDuration !== undefined && minimumDuration !== null &&
                                <div className="bookingDurationRestricted">
                                    La durée du séjour doit être de <b>{minimumDuration}</b> jour(s) minimum.
                                </div>
                            ) 
                            || 
                            (
                                bookingDurationRestricted === true && currentRoom !== '' && maximumDuration !== undefined && maximumDuration !== null &&
                                <div className="bookingDurationRestricted">
                                    La durée du séjour doit être de <b>{maximumDuration}</b> jour(s) maximum.
                                </div>
                            ) 
                            || 
                            (
                                bookingDurationRestricted === true && currentRoom !== '' && currentRoom.minimumDuration == false && currentRoom.maximumDuration == false &&
                                <div className="bookingDurationRestricted">
                                    Séjour non disponible.
                                </div>
                            )
                            ||
                            (                        
                                amount == 0 && currentLockerType !== '' && startDate !== '' && endDate !== '' &&
                                <div className="noPricePeriod">
                                    Pas de casiers disponibles.
                                </div>
                            )
                        } 

                        <div className="night">
                            {amount !== 0 && bookingDurationRestricted === false && currentRoom !== '' && currentLockerType !== '' && night !== 0 &&
                                <p> 
                                    Pour&nbsp;<strong>{night} jour(s)</strong>
                                </p>
                            }
                        </div>
                
                        {bookingDurationRestricted === false && price !== 0 && price !== -1 && currentLockerType !== '' &&  startDate !== '' && endDate !== '' &&
                            <div className="cgv">
                                <input type="checkbox" id="cgv_checkbox" checked={cgvAccepted} onClick={this.handleCheck} key={cgvAccepted}/>
                                <label for="cgv_checkbox">J'accepte les conditions générales de vente consultables en </label>
                                <a href={"https://myskicase.com/www/privacy.php?roomId="+currentRoom.id} target="_blank">cliquant ici</a>
                            </div>
                        }
                        {amount !== 0 && amount !== -1 && currentLockerType !== '' &&  startDate !== '' && endDate !== '' &&
                        <p disabled>
                                <StripePayment 
                                    price={amount} 
                                    item={currentLockerType+' - '+currentRoom.name} 
                                    startDate={startDate}                                    
                                    endDate={endDate}
                                    currentLockerTypeId={currentLockerTypeId}
                                    roomId={currentRoom.id}                                                                    
                                    defaultSkiLocker={defaultSkiLocker}                                                                    
                                    defaultPrice={defaultPrice}  
                                    disabled= {cgvAccepted != true}
                                    seasonBooking = {seasonBooking}
                                />
                            </p>
                        }

{/* const stripe = require('stripe')('sk_test_VePHdqKTYQjKNInc7u56JBrQ');

const paymentIntent = await stripe.paymentIntents.create({
  amount: 1099,
  currency: 'usd',
  setup_future_usage: 'off_session',
}); */}
                        
                    </div>
                    
    
                </div>
            );
        }
        
    }

}

export default Booking;
