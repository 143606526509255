import "./App.css";
import React, { Component } from "react";
import Main from "./scenes/main/Main.jsx";
import params from "./params.json";

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      dictionary: [],
      rooms: [],
      customerId: 0,
      apiEndpoint: params.APIWEBAPP.endpoint,
      apiAuth: params.APIWEBAPP.auth,
    };
  }

  componentDidMount() {
    const { apiEndpoint, apiAuth } = this.state;

    // header request options
    const requestOptions = {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      Authorization: "Basic " + apiAuth,
    };
    // Get resort list
    fetch(apiEndpoint + "/resorts", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        this.setState({ rooms: data });
      })
      .catch((error) => console.log(error));
    // Get dictionary
    fetch(apiEndpoint + "/dictionary", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        this.setState({ dictionary: data });
      })
      .catch((error) => console.log(error));
  }

  // Next step function
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  // Previous step function
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  // Change step
  changeStep = (newStep) => {
    this.setState({
      step: newStep,
    });
  };

  // set Customer Id
  setCustomerId = (customerId) => {
    this.setState({
      customerId: customerId,
    });
  };

  /*signOut = () =>{
    this.setCustomerId(0);
    this.changeStep(0);
  }*/

  render() {
    const { step, dictionary, rooms, customerId } = this.state;
    const { apiEndpoint, apiAuth } = this.state;
    if (step === 3) {
      document.body.classList.remove("gradient");
      document.body.classList.add("gradient_diag");
    } else {
      document.body.classList.remove("gradient_diag");
      document.body.classList.add("gradient");
    }

    return (
      <div className="App">
        <header className="App-header">
          <div className="container">
            <div className="Logo">
              <a href="https://myskicase.com">
                <img src="./img/logo_skicase_color_w.svg" alt="logo" />
              </a>
            </div>
            <div class="menu">
              <div class="link">
                <a href="https://myskicase.com/nos-consignes-partenaires">
                  Nos consignes partenaires
                </a>
              </div>
              <div class="link">
                <a href="https://myskicase.com/nos-casiers-a-consigne-pour-skis-et-bagages">
                  Type de casiers
                </a>
              </div>
              <div class="link link-current">
                <a href="/">Réserver</a>
              </div>
              <div class="link">
                <a href="https://myskicase.com/faq-questions-reponses/">FAQ</a>
              </div>
              <div class="link">
                <a href="https://myskicase.com/contact-infos">
                  Contact &amp; Infos
                </a>
              </div>
            </div>
          </div>
        </header>
        <Main
          step={step}
          dictionary={dictionary}
          rooms={rooms}
          apiEndpoint={apiEndpoint}
          apiAuth={apiAuth}
          customerId={customerId}
          nextStep={this.nextStep}
          prevStep={this.prevStep}
          changeStep={this.changeStep}
          setCustomerId={this.setCustomerId}
        />
      </div>
    );
  }
}

export default App;
