import './Dashboard.css';
import React, { Component } from 'react'
import Order from '../order/Order';

export class Dashboard extends Component{

    constructor(props){
        super(props);
        this.state = {
            'orders': [],
            'refreshOrder': true,
        }
    }

    

    getOrders() {
        const { customerId } = this.props;
        const { apiEndpoint, apiAuth } = this.props;
        // check login
        let route = apiEndpoint+'/orders/user/'+customerId;
        const requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              "Access-Control-Allow-Origin": "*"
            },
            Authorization: 'Basic '+apiAuth,
          };
        fetch(route,requestOptions)
        .then(res => res.json())
        .then((data) => {
            this.setState({
                'orders': data,
            });

        })
        .catch(error => console.log(error));      
    }

    handleClick = e =>{
        const { apiEndpoint, apiAuth } = this.props;
        const { customerId } = this.props;
        const { customerName, customerEmail, customerPhone } = this.props;
        e.preventDefault();
        if(customerId > 0 && (customerName === '' || customerEmail === '' || customerPhone === '')){
          // header request options
          const requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              "Access-Control-Allow-Origin": "*"
            },
            Authorization: 'Basic '+apiAuth
          };
          // Get resort list
          fetch(apiEndpoint+'/customers/'+customerId,requestOptions)
            .then(res => res.json())
            .then(data => {
                this.props.setCustomer(data.name,data.email,data.mobile);
            })
            .catch(error => console.log(error));
        }
        this.props.changeStep(0);
    }


    render(){
        const { orders, refreshOrder } = this.state;
        const { rooms } = this.props;
        if(refreshOrder){
            this.getOrders();
            this.setState({
                'refreshOrder': false,
            });
        }
        console.log(orders);
        return(
            <div className="Dashboard">
                {orders.length > 0 && orders.map((order, i) => {                
                        // Return the element. Also pass key     
                        return (
                            <Order order={order} rooms={rooms} />
                        ) 
                    })}
                <div className="Book-now">
                    <div className="Book-now_btn" onClick={this.handleClick}>                        
                        Faire une réservation
                    </div>
                </div>
            </div>
        );
    };

}

export default Dashboard