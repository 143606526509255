import './Order.css';
import React, { Component } from 'react';
import Moment from 'moment';

export class Order extends Component{

    // eslint-disable-next-line no-useless-constructor
    constructor(props){
        super(props);
    }

    render(){
        const { order, rooms } = this.props;
        const currentRoom = rooms.find(x => x.name === order.resort);
        const longitude = currentRoom.longitude;
        const latitude = currentRoom.latitude;
        return(
            <div className="Order">
                    <h3>{order.resort}</h3>
                    <div className="Order-date">
                        Du {Moment(order.startDate).format('DD MMMM YYYY')} à partir de <strong>8h00</strong><br />
                        Au {Moment(order.endDate).format('DD MMMM YYYY')} jusqu'à <strong>18h00</strong>
                    </div>
                    <hr />
                    <div className="content">
                        <div className="content-left">                            
                            <div className="Order-locker">
                                <div className="locker">
                                    <div className="Locker-label">
                                        Casier N°
                                    </div>
                                    <div className="Locker-info">
                                        {order.skilockerNumber}
                                    </div>
                                </div>
                                <div className="code">
                                    <div className="Locker-label">
                                        Code casier
                                    </div>
                                    <div className="Locker-info">
                                        {order.skilockerPass}
                                    </div>
                                </div>
                            </div>
                            <div className="Order-buttons">
                                <div className="btn btn-darkBlue">Ouvrir mon casier</div>                                
                                <div className="btn btn-lightBlue_transparent"> <a href="mailto:?subject=Accès consigne ALPE D HUEZ&body=Mon casier pour la consigne à ALPE D HUEZ est 12 et mon code est 5435" target="_blank">Partager mon code</a></div>
                            </div>
                            <div className="Order-links">
                                <div className="link-hotline">
                                    <img src="" alt="" />
                                    Hotline +33 4 58 00 03 93
                                </div>
                                <div className="link-goto">
                                    <img src="" alt="" />
                                    <a href={"https://www.google.com/maps/place/"+latitude+","+longitude} target="_blank">Se rendre au local à casier</a>
                                </div>
                            </div>
                        </div>
                        
                        <div className="content-right">
                            <table width="100%">
                                <tbody>
                                    <tr>
                                        <td>N° Réservation</td>
                                        <td className="color_darkBlue">{order.id}</td>
                                    </tr>
                                    <tr>
                                        <td>Consigne 4/5 paires</td>
                                        <td className="color_darkBlue">{order.price}€</td>
                                    </tr>
                                    <tr>
                                        <td>0 forfait(s) associé(s)</td>
                                        <td className="color_darkBlue">Gestion des forfaits</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div>
                                +33 4 76 26 76 84
                            </div>
                            <div>
                                <a href="mailto:?subject=Modification réservation 73456&body=Je souhaite modifier ma réservation n°73456" target="_blank">
                                    Modifier réservation
                                </a>                                
                            </div>
                            <div>
                                <a href="mailto:?subject=Annulation réservation 73456&body=Je souhaite annuler ma réservation n°73456" target="_blank">                                    
                                    Annuler réservation
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}

export default Order;