import React, { useState, useEffect } from "react";
import "./Stripe.css";

const ProductDisplay = (props) => {

    return (
        <section>
            <form action="https://apiwebapp.myskicase.com/order/create-order" method="POST">
                <input type="hidden" name="price" value={props.price} />
                <input type="hidden" name="item" value={props.item} />
                <input type="hidden" name="email" value={props.email} />
                <input type="hidden" name="startDate" value={props.startDate} />
                <input type="hidden" name="endDate" value={props.endDate} />
                <input type="hidden" name="currentLockerTypeId" value={props.currentLockerTypeId} />
                <input type="hidden" name="roomId" value={props.roomId} />
                <input type="hidden" name="defaultSkiLocker" value={props.defaultSkiLocker} />
                <input type="hidden" name="defaultPrice" value={props.defaultPrice} />
                <input type="hidden" name="mobile" value={props.mobile} />
                <input type="hidden" name="name" value={props.name} />
                <input type="hidden" name="seasonBooking" value={props.seasonBooking} />
                {props.customerId > 0 &&
                    <input type="hidden" name="customerId" value={props.customerId} />
                }
                <input type="hidden" name="url" value='https://apiwebapp.myskicase.com/order/create-order' />    
                {props.disabled ?          
                    <button type="submit" className="button button-grey xx" disabled={props.disabled} id="book">
                        Réserver !
                    </button>
                :
                    <button type="submit" className="button button-blue xx" disabled={props.disabled} id="book">
                        Réserver !
                    </button>
                }
            </form>

        </section>

    );
};

const Message = ({ message }) => (
    <section>
        <p>{message}</p>
    </section>
);


export default function StripePayment(props) {
    const [message, setMessage] = useState("");
    const { price, item, email, startDate, endDate, currentLockerTypeId, roomId, defaultSkiLocker, defaultPrice, mobile, name, customerId, disabled, seasonBooking } = props;

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            setMessage("Order placed! You will receive an email confirmation.");
        }

        if (query.get("canceled")) {
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
    }, []);

    return message ? (
        <Message message={message} />
    ) : (
        <ProductDisplay
            price={price}
            item={item}
            email={email}
            startDate={startDate}
            endDate={endDate}
            currentLockerTypeId={currentLockerTypeId}
            roomId={roomId}
            defaultSkiLocker={defaultSkiLocker}
            defaultPrice={defaultPrice}
            mobile={mobile}
            name={name}
            customerId={customerId}
            disabled={disabled}
            seasonBooking={seasonBooking}
        />
    );
}
