import './Login.css'
import React, { Component } from 'react';

export class Login extends Component{

    constructor(props){
        super(props);
        this.state={
            login: '',
            password: '',
            error: {
                status: false,
                message: ''
            },
        }
    }

    handleChange = e =>{
        e.preventDefault();
        const inputName = e.target.name;
        const inputValue = e.target.value;
        this.setState({
            [inputName]: inputValue,
            error: {
                status: false,
                message: ''
            },
        });
    }

    handleKeyDown = e =>{        
        if(e.key === 'Enter') {
            this.login(e);
        }
    }

    login = e =>{
        e.preventDefault();
        const { login, password } = this.state;
        const { apiEndpoint, apiAuth } = this.props;
        // check login
        let route = apiEndpoint+'/customers/login';
        const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              "Access-Control-Allow-Origin": "*"
            },
            Authorization: 'Basic '+apiAuth,
            body: JSON.stringify({
                'login': login,
                'password': password,
            })
          };
        fetch(route,requestOptions)
        .then(res => res.json())
        .then((data) => {
            if(data.status === 'success'){
                this.props.setCustomerId(data.customer.id);
                this.nextStep();
            }
            else{
                this.setState({     
                    error: {
                        status: true,
                        message: 'Identifiant ou mot de passe erroné'
                    },   
                });
            }
        })
        .catch(error => console.log(error))
    }

    nextStep(){
        this.props.changeStep(3);
    }

    render(){
        const { login, password } = this.state;
        const { error } = this.state;
        return(
            <div className="LoginContainer">
                <div className="Login">
                    <h2>Bienvenue !</h2>

                    <div className="Login-fields">
                        <input type="email" name="login" placeholder="Email" value={login} onChange={this.handleChange} onKeyDown={this.handleKeyDown} />                    
                        <input type="password" name="password" placeholder="Mot de passe" value={password} onChange={this.handleChange} onKeyDown={this.handleKeyDown} />
                    </div>
                    <div className="Login-error" style={{visibility: error.status ? 'visible' : 'hidden' }}>
                        {error.message}
                    </div>
                    <div className="Login-submit" onClick={this.login}>
                        <div className="button button-blue">Se connecter</div>
                    </div>
                </div>
            </div>
            
        );
    }
}

export default Login;