import './Search.css';
import './Calendar.css';
import React, { Component } from 'react'
import Litepicker from 'litepicker';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import 'react-phone-input-2/lib/style.css'
import 'react-phone-input-2/lib/material.css'
import Moment from 'moment';
import './Phone.css';
import FadeIn from "react-fade-in";

export class Search extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customerHidden: true,
            values: '',
            picker: '',
            urlRoomName: ''
        }
    }

    componentWillMount() {
        const url = new URL(window.location.href);
        this.cr = url.searchParams.get("currentRoom");
    }

    componentDidMount() {
        this.getParamsUsed = false
        const resort = window.location.href.split('/')[3];
        const { apiEndpoint, apiAuth } = this.props;
        if (resort !== '') {
            let url = apiEndpoint + '/resorts/slug/' + resort;

            // header request options
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                },
                Authorization: 'Basic ' + apiAuth
            };
            // Get resort list
            fetch(url, requestOptions)
                .then(res => res.json())
                .then(data => {
                    if (data !== '') {
                        this.setRoom(null, data[0]);
                        // this.setState({
                        //     urlRoomName: data[0].name,
                        // });
                    }
                })
                .catch(error => console.log(error));
        }
    }


    initDatepicker = (room) => {
        let startDate = document.querySelector('#startDate');
        let endDate = document.querySelector('#endDate');
        let firstDate;
        let lastDate;

        // set min max start date
        const today = Moment().endOf('day').format('YYYY-MM-DD');
        if (Moment(today).isAfter(room.openDate)) {
            firstDate = today;
        }
        else {
            firstDate = room.openDate;
        }
        // set min max end date
        lastDate = room.closeDate;

        const picker = new Litepicker({
            element: startDate,
            elementEnd: endDate,
            inlineMode: false,
            singleMode: false,
            numberOfMonths: 2,
            numberOfColumns: 2,
            lang: 'fr',
            minDate: firstDate,
            maxDate: lastDate,
            format: 'DD/MM/YYYY',
            autoApply: true,
            showTooltip: true,
            allowRepick: true,
            tooltipNumber: (totalDays) => {
                return totalDays;
            },
            tooltipText: { "one": "jour", "other": "jours" },
        });
        picker.gotoDate(firstDate);
        picker.on('selected', (startDate, endDate) => {
            this.props.setStartDate(Moment(startDate.toDateString()).format('YYYY-MM-DD'));
            this.props.setEndDate(Moment(endDate.toDateString()).format('YYYY-MM-DD'));
        });

        this.setState({
            picker: picker,
        });

    }

    setRoom(e, room) {
        const { step } = this.props;
        if (room != null) {
            const value = room.name;
            if (value !== '') {
                if (room !== undefined) {
                    this.props.setCurrentRoom(room);
                    if (this.state.picker) {
                        this.state.picker.clearSelection();
                    }
                    //this.initDatepicker(room);
                    if (step === 0) {
                        this.props.nextStep();
                    }
                    else {
                        this.props.setLockerType('');
                    }
                }
            }
        }
        else {
            this.props.setCurrentRoom('');
            if (step === 1) {
                this.props.prevStep();
            }
        }
        this.props.setStartDate('');
        this.props.setEndDate('');

    }

    setStartDate = e => {
        e.preventDefault();
        if (this.props.endDate !== '') {
            if (!Moment(this.props.endDate).isAfter(e.target.value)) {
                this.props.setEndDate('');
            }
        }
        this.props.setStartDate(e.target.value);
    }

    setEndDate = e => {
        e.preventDefault();
        this.props.setEndDate(e.target.value);
    }

    async setLockerType(lockerType) {
        const { dictionary, currentRoom } = this.props;
        if (lockerType != null) {
            await this.props.setLockerType(
                lockerType[1],
                lockerType[0]);
            this.initDatepicker(currentRoom);
        }
        else {
            this.props.setLockerType('', 0);
        }
    }

    setSeason = e => {
        const { currentRoom } = this.props;
        if (currentRoom !== '' && currentRoom.isBookingOpen == 1 && currentRoom.isSeasonBookingEnabled == 1) {
            this.props.setSeason(e.target.checked);
            if (e.target.checked === true) {
                let currentDate = new Date().toJSON().slice(0, 10);
                if (currentRoom.openSeasonDate <= currentDate) {
                    this.props.setStartDate(currentDate);
                } else {
                    this.props.setStartDate(currentRoom.openSeasonDate);
                }
                this.props.setEndDate(currentRoom.closeSeasonDate);
                this.state.picker.clearSelection();
            }
            else {
                this.props.setStartDate('');
                this.props.setEndDate('');
            }
        }
        else {
            e.preventDefault();
        }
    }

    disableDefaultDateInput = e => {
        e.preventDefault();
    }

    updateQueryStringParameter = e => {
        const { currentRoom, currentLockerType, startDate, endDate, seasonBooking } = this.props;
        // const url = new URL(window.location.href);
        // url.searchParams.set("currentRoom", currentRoom.slug);
        // window.history.pushState({}, '', url.toString());
        window.history.replaceState({}, '', currentRoom.slug);
    }


    render() {
        const { rooms, currentRoom, dictionary, currentLockerType, seasonBooking, startDate, endDate, roomList } = this.props;
        const { urlRoomName } = this.state;

        if (this.cr != null && this.cr != "undefined" && Object.keys(rooms).length != 0 && currentRoom == "" && !this.getParamsUsed) {
            this.getParamsUsed = true;
            var result = Object.keys(rooms).find(key => rooms[key].id === this.cr.toString());
            this.setRoom(null, rooms[result]);
        }

        let locker;
        if (currentRoom && currentRoom !== '') {
            locker = Object.entries(currentRoom.lockerType);
        }
        else {
            locker = Object.entries([
                [' ']
            ]);
        }

        return (


            <div className="search">
                <div className="field field-first">
                    {(urlRoomName !== '') &&
                        <div className="roomNameMobile">
                            {currentRoom.name}
                        </div>
                    }
                    {(urlRoomName === '') &&
                        <Autocomplete
                            id="combo-box-resort"
                            key={roomList}
                            className="where"
                            options={rooms}
                            value={currentRoom}
                            ListboxProps={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            style={{ width: 280 }}
                            renderInput={(params) => <TextField {...params} label="Où skiez vous ?" variant="outlined" />}
                            onChange={(event, selectedRoom) => {
                                this.setRoom(event, selectedRoom);
                                setTimeout(() => {
                                    this.updateQueryStringParameter();
                                }, 10);
                            }}
                        />
                    }
                </div>
                {currentRoom !== '' && currentRoom.resortDisplay !== "0" && currentRoom.isBookingOpen !== "0" &&
                    <FadeIn>
                        <div className="field">
                            <Autocomplete
                                id="combo-box-locker"
                                key={currentRoom.id}
                                className="locker"
                                options={locker}
                                // getOptionLabel={(option) => option[1].split('_')[3] != undefined
                                //                     ?  dictionary.fr[option[1].split('_')[0]+'_'+option[1].split('_')[1]+'_'+option[1].split('_')[2]] +' - '+currentRoom.subRooms[option[1].split('_')[3]]
                                //                     :  dictionary.fr[option[1]]
                                // }
                                getOptionLabel={(option) => option[1]}
                                style={{ width: 230 }}
                                renderInput={(params) => <TextField {...params} label="Quel casier ?" variant="outlined" />}
                                onChange={(event, selectedLocker) => {
                                    this.setLockerType(selectedLocker);
                                    setTimeout(() => {
                                        this.updateQueryStringParameter();
                                    }, 10);
                                }}
                                disabled={(currentRoom === '' || currentRoom.isBookingOpen === 0)}
                            />
                        </div>
                    </FadeIn>
                }

                {/* Start date desktop */}
                {currentLockerType !== '' &&

                    <FadeIn>
                        <div className="field arrival" style={{ background: seasonBooking ? '#eee' : '#fff' }}>
                            <label htmlFor="startDate">Arrivée</label>
                            <input
                                type="text"
                                name="startDate"
                                id="startDate"
                                placeholder="Quand ?"
                                value={(startDate === '') ? '' : Moment(startDate).format('DD/MM/YYYY')}
                                disabled={(seasonBooking || (currentRoom === '' && currentLockerType === '') || currentRoom.isBookingOpen === 0)}
                                onClick={this.disableDefaultDateInput}
                                autoComplete="off"

                            />
                            {/* Season Booking desktop */}
                            {currentRoom.isSeasonBookingEnabled == 1 && currentRoom.openSeasonDate != null && currentRoom.closeSeasonDate != null && 
                                <div className="seasonBkooking" >
                                    <input type="checkbox" id="seasonBooking" onClick={this.setSeason} />
                                    <label htmlFor="seasonBooking">Réservation saison</label>
                                </div>
                            }
                        </div>
                    </FadeIn>
                }
                {currentLockerType !== '' &&

                    <FadeIn>
                        {currentRoom.isSeasonBookingEnabled == 1 &&

                            <div className="seasonBkookingMobile" >
                                <input type="checkbox" id="seasonBooking" onClick={this.setSeason} />
                                <label htmlFor="seasonBooking">Réservation saison</label>
                            </div>
                        }
                        <div className="field departure" style={{ background: seasonBooking ? '#eee' : '#fff' }}>
                            <label htmlFor="endDate">Départ</label>
                            <input
                                type="text"
                                name="endDate"
                                id="endDate"
                                placeholder="Quand ?"
                                disabled={(seasonBooking || currentRoom === '' || currentRoom.isBookingOpen === 0)}
                                value={(endDate === '') ? '' : Moment(endDate).format('DD/MM/YYYY')}
                                autoComplete="off"
                            // min={minEndDate}
                            // max={maxEndDate}
                            />
                        </div>
                        {/* startDate mobile */}
                        <div className="field startDateMobile" style={{ background: seasonBooking ? '#eee' : '#fff' }}>
                            <label htmlFor="startDateMobile">Date de Début</label>
                            <input
                                type="date"
                                name="startDateMobile"
                                id="startDateMobile"
                                placeholder="Quand ?"
                                min={currentRoom.openDate}
                                max={currentRoom.closeDate}
                                onChange={this.setStartDate}
                                disabled={(seasonBooking || currentRoom === '' || currentRoom.isBookingOpen === 0)}
                                value={startDate}
                            />
                        </div>
                        {/* endDate mobile */}
                        <div className="field endDateMobile" style={{ background: seasonBooking ? '#eee' : '#fff' }}>
                            <label htmlFor="endDateMobile">Date de fin</label>
                            <input
                                type="date"
                                name="endDateMobile"
                                id="endDateMobile"
                                min={(startDate !== '') ? startDate : currentRoom.openDate}
                                max={currentRoom.closeDate}
                                onChange={this.setEndDate}
                                disabled={(seasonBooking || currentRoom === '' || currentRoom.isBookingOpen === 0)}
                                value={endDate}
                            />
                        </div>
                    </FadeIn>
                }
            </div>
        )
    }
}

export default Search;

